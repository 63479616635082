<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Contact Person</h4>
    <div>
      <button @click="addMore" type="button" class="btn btn-primary me-1">+ Add new</button>
    </div>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':contacts})">
      <div class="row d-flex align-items-end">

        <div v-for="(contact, index) in contacts" :key="index" class="col-md-5 col-5 card_body box_shadow">
          <div class="row d-flex align-items-end">

            <div class="col-12 org_icon_col">
              <div v-if="contacts.length > 1" @click="remove(index)" class="org_dlt_icon">
                <button class="btn btn-outline-danger text-nowrap px-1 button_icon" type="button">
                   <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>

            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="contact.contact_person" type="text" class="form-control" id="full_name"
                       placeholder="Full name"/>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="contact.designation" type="text" class="form-control"
                       id="designation" placeholder="Designation"/>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="contact.department" type="text" class="form-control"
                       id="department" placeholder="Department"/>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="contact.mobile" type="text" class="form-control"
                       id="mobile" placeholder="Mobile"/>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="contact.email" type="text" class="form-control"
                       id="email" placeholder="Email"/>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row mt-5 d-flex align-items-end">
        <div class="col-sm-4 offset-4">
          <button type="submit" class="btn btn-primary me-1">Submit</button>
          <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})

const contacts = ref([
  {
    contact_person: '',
    designation: '',
    department: '',
    mobile: '',
    email: '',
  }
])

const addMore = () => {
  contacts.value.push({
    contact_person: '',
    designation: '',
    department: '',
    mobile: '',
    email: '',
  })
}

const remove = index => {
  contacts.value.splice(index, 1)
}

const reset = () => {
  contacts.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    contacts.value = props.items
  }
}

</script>

<style scoped></style>
