<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Basic Info</h4>
  </div>
  <div class="card-body mt-2">
    <form class="form form-horizontal" @submit.prevent="$emit('storeContact', formData)">

      <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
          <div class="row">

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full_name" class="form-control" v-model="formData.full_name"
                       placeholder="Full name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="legal_name" class="form-control" v-model="formData.legal_name"
                       placeholder="Legal name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="bin_no" class="form-control" v-model="formData.bin_no"
                       placeholder="Bin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="tin_no" class="form-control" v-model="formData.tin_no"
                       placeholder="Tin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="reg.no" class="form-control" v-model="formData.reg_no"
                       placeholder="Registration No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <AsyncSelect
                    placeholder="Select area"
                    v-model="formData.location_area"
                    :api-service="fetchAreaList"
                />
              </div>
            </div>

            <div class="col-12">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>Role</th>
                  <th>Serial No</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(companyRole, index) in formData.company_roles_data">
                  <td class="p-1 w-50">
                    <v-select
                        @option:selected="(selectedRole) => getRoleChange(selectedRole, index)"
                        placeholder="Select Role"
                        v-model="companyRole.id"
                        :disabled='!companyRole.is_new'
                        :options="companyRules"
                        label="name"
                        :reduce="name => name.id"
                    />
                  </td>
                  <td class="p-1 w-25">
                    <input type="text" class="form-control" :disabled='!companyRole.is_new' v-model="companyRole.serial_no"/>
                  </td>
                  <td class="d-flex p-1">
                    <select :disabled='!companyRole.is_new' v-model="companyRole.status" class="form-control">
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleCrm from '@/services/modules/crm/crm'

const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const formData = ref({
  full_name: '',
  legal_name: '',
  location: '',
  bin_no: '',
  tin_no: '',
  reg_no: '',
  location_id: '',
  location_area: '',
  company_rules: [],
  company_roles_data: [],
  dealer_register:true
})

const { fetchAreaList } = handleCrm();

watch(() => [props.formData, props.companyRules], ([newFormData, newFormData2]) => {
  formDataSet();
});

const getRoleChange = (selectedRole, index) => {
    formData.value.company_roles_data[index] = {
      ...formData.value.company_roles_data[index],
      id: selectedRole.id,
      label: selectedRole.name,
      value: selectedRole.value,
    };

    formData.value.company_rules = formData.value.company_roles_data.map(role => parseInt(role.id));
};


const formDataSet = () => {
  if (props.formData && props.formData.full_name) {
    formData.value = props.formData
    formData.value.company_rules = props.formData.company_roles_data.map(role => parseInt(role.id));
  }
  if (props.companyRules.length > 0 && props.companyRules.length <= 2 && !props.formData.company_roles_data) {
    formData.value.company_rules = [props.companyRules[0].id];
    formData.value.company_roles_data = formData.value.company_roles_data || [];

    formData.value.company_roles_data.push({
      is_new: true,
      id: props.companyRules[0].id,
      label: props.companyRules[0].name,
      value: props.companyRules[0].value,
      serial_no: '',
      status: 'active',
    });
  }
}
onMounted(() => {
  formDataSet()
})
</script>
