<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Link Accounts</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater">

      <div class="row">
        <div class="col-4">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Staff Reference: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal()">
                            <i class="fas fa-search"></i>
                          </span>
          </div>
          <AsyncSelect
              placeholder="Select Staff Reference"
              v-model="formData.contact_owner"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1, roles: 'employee', is_not_contact_owner: true }"
              :format-label="formatPatientLabel"
          />
        </div>

        <div class="col-4">
          <label for="colFormLabel" class="col-form-label">Linked User</label>
          <AsyncSelect
              placeholder="Select User"
              v-model="formData.linked_user"
              :api-service="fetchContacts"
              :additional-query="{ check_exists: 1, is_not_linked_user: true}"
          />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-12">
          <button
              :disabled="loading" @click="submit"
              class="btn btn-primary me-1 waves-effect waves-float waves-light"
          >
            Submit
          </button>
          <button type="button" class="btn btn-outline-secondary">Reset</button>
        </div>
      </div>

    </form>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        :type="roleType"
    />
  </div>
  <Loader v-if="loading"/>
</template>

<script setup>
import {defineProps, inject, onMounted, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCrm from '@/services/modules/crm/crm'
import handleCompanyContact from "@/services/modules/companyContact";
import {useRoute} from "vue-router";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import Loader from '@/components/atom/LoaderComponent'
import {useStore} from "vuex";
import handleContact from "@/services/modules/contact";
import handleImportTracker from "@/services/modules/procurement/importTracker";
const { fetchContacts } = handleImportTracker();

const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  items: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchAllDealers} = handleCrm();
const {updateContactDealer} = handleCompanyContact();
const route = useRoute();
const store = useStore()

const singleDealer = ref(null);
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const roleType = ref('employee')
const onOpenContactSearchModal = (role = 'employee') => {
  roleType.value = role
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  if (roleType.value == 'employee') {
    formData.value.contact_owner = contact
    formData.value.contact_owner_id = contact.id
    return
  }
  formData.value.key_account_manager = contact
  formData.value.key_account_manager_id = contact.id
}
const formData = ref({
  contact_owner: '',
  contact_owner_id: '',
  key_account_manager: '',
  key_account_manager_id: '',
  linked_user: '',
  linked_user_id: '',
})

const submit = async () => {

  const contactId = props.formData.id;
  if (!contactId) {
    return showError('Contact id is required');
  }

  const params = {
    company_id: route.params.companyId,
    dealer_id: singleDealer.value ? singleDealer.value.id : null,
    contact_owner_id: formData.value.contact_owner ? formData.value.contact_owner.id : null,
    key_account_manager_id: formData.value.key_account_manager ? formData.value.key_account_manager.id : null,
    linked_user_id: formData.value.linked_user ? formData.value.linked_user.id : null,
  };

  loading.value = true

  await updateContactDealer(contactId, params).then((res) => {
    loading.value = false
    if (!res.status) return showError(res.message);
    return showSuccess(res.message);
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

watch(() => props.formData, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.formData.dealer_id) {
    singleDealer.value = {
      id: props.formData.dealer_id,
      name: props.formData.dealer ? props.formData.dealer.name : ''
    }
  }
  if (props.formData.contact_owner_id || props.formData.key_account_manager || props.formData.linked_user) {
    formData.value = props.formData
  }
}

onMounted(() => {
  formDataSet()
})

</script>

